<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div v-on="$listeners" class="btn-control" :class="{ disabled: disabled }">
    <i class="fas fa-plus-circle text-white-50"></i>
  </div>
</template>

<style scoped>
.btn-control {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  /* transition: 0.3s; */
}

.close {
  transform: rotate(45deg);
}

.btn-control.hover {
  background: rgba(255, 255, 255, 0.2);
}
.disabled {
  cursor: not-allowed;
}
.disabled.hover {
  background: transparent;
}

/* .feather {
  width: 100%;
  height: 100%;
  stroke: var(--black);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transition: 0.3s;
} */
</style>
